import { div } from "prelude-ls";
import React from "react";
import "../App.css";
import { bookData } from "./data";

export const Books = () => {
  return (
    <>

      <div className="main-wrap">
      {bookData.map((data, index) => {
    	return (
<div>

<h2 className="cards-title">{data.category}</h2>
      <div className="cards" key={index}>
       	{data.booklist.map((subitem, i) => {
       	 return (
       	 <div className="card">
       	 		<Stock
        				title={subitem.title}
        				image={subitem.image}
        			/>    
         </div>

          )
         })}
       </div>
</div>         
) 
      })}


      

      </div>
    </>
  );
};


const Stock = ({ title, image }) => {
  if (!title) return <div />
  return (
      <div>
        <img className="card" src={image} alt="not read"/>
        <h5>{title}</h5>
      </div>
  );
};