import React, { Component } from 'react';
import "./App.css";
import { Books } from "./components/book";
import { Icon } from "./components/icon";
import { Home } from './components/home';
import { Notfound } from './components/notfound';
import  CategoryPage  from './components/categorypage';
import {BrowserRouter, Route, Switch} from 'react-router-dom'

// function App() {
//     return ( <
//         div className = "App" >
//         <Books/>
//         </div>
//     );
// }


class App extends Component {



  componentDidUpdate(prevProps){
    if (this.props.location !== prevProps.pathname) {
        window.gtag("config", "G-Q4PGQ2QDMS", {
            page_title: this.props.location.pathname,
            page_path: this.props.location.pathname,
        })
        console.log(window.location.pathname + window.location.search)
    }
}


    render() {
      return (
        <div className="App">
          <BrowserRouter>
            <Switch>
            <Route path='/' exact component={Home}/>
            <Route path='/search' exact component={Icon} />
            <Route path='/categories/:category' component={CategoryPage} />
            <Route path='/allbooks' exact component={Books}/>
            <Route component={Notfound} />
              {/* <Route path='/' exact component={LandingPage}/>
              <Route path='/search' exact component={Icon} />
              <Route path='/:item_slug' exact component={SinglePageCard} />
              <Route path='/author/:author_slug' exact component={AuthorCard} />
              <Route path='/categories/:category_slug' component={CategoryCard} /> */}
              
            </Switch>
          </BrowserRouter>
        </div>
      );
    }
  }
  




export default App;