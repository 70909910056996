import React, { useState } from "react";
// import Cards from './cards';
import { Link } from "react-router-dom";
import { bookData } from "./data";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import FloatingLabel from "react-bootstrap/FloatingLabel";
import Hangman from "./Hangman";




function shuffleArray(array) {
  let i = 0;
  //Spent 2 hours to resolved the issue. finally const was the appropriate decleration
  //debuging here was so much, mainly cause of
  const booklistarr = [];
  const careerlistarr = [];
  const botharr = [];
  let randomfrombookarry, randomfromtitlearry, randomfromcareerarry;
  for (i = 0; i < 4; i++) {
    randomfrombookarry = Math.floor(Math.random() * (bookData.length - 1 + 1));
    randomfromtitlearry = Math.floor(
      Math.random() * (bookData[randomfrombookarry].booklist.length - 1 + 1)
    );
    randomfromcareerarry = Math.floor(
      Math.random() * (bookData[randomfrombookarry].careerlist.length - 1 + 1)
    );
    if (booklistarr?.push) {
      booklistarr.push({
        bookname:
          bookData[randomfrombookarry].booklist[randomfromtitlearry].title,
        bookimg:
          bookData[randomfrombookarry].booklist[randomfromtitlearry].image,
        category: bookData[randomfrombookarry].category,
      });
    } else {
      console.log("arr is undefined or null");
    }
    //video title being non utf and im still figuring out why react cant read it sometimes?
    if (careerlistarr?.push) {
      if (
        bookData[randomfrombookarry].careerlist[randomfromcareerarry]?.title
      ) {
        careerlistarr.push({
          vidtitle:
            bookData[randomfrombookarry].careerlist[randomfromcareerarry]
              ?.title,
          vidlink:
            bookData[randomfrombookarry].careerlist[randomfromcareerarry].link,
          category: bookData[randomfrombookarry].category,
        });
      } else {
        console.log(
          "bookData[randomfrombookarry]" +
            bookData[randomfrombookarry].category +
            " bookData[randomfrombookarry].careerlist[randomfromcareerarry]" +
            bookData[randomfrombookarry].careerlist[randomfromcareerarry]
        );
        booklistarr.pop();
        i = i - 1;
      }
    } else {
      console.log("arr is undefined or null");
      // console.log(bookData[randomfrombookarry].careerlist[randomfromcareerarry].title);
      console.log(
        " bookData[randomfrombookarry]" +
          bookData[randomfrombookarry] +
          " bookData[randomfrombookarry].careerlist[randomfromcareerarry] " +
          bookData[randomfrombookarry].careerlist[randomfromcareerarry].title
      );
    }
  }
  botharr.push(booklistarr);
  botharr.push(careerlistarr);
  return botharr;
}

export const Home = () => {
  const shuffledPosts = shuffleArray(bookData);
  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  let quotes = [
    ' "There is no time for cut-and-dried monotony. There is time for work. And time for love. That leaves no other time. ~ Coco Chanel,fashion icon"',
    '"A people without the knowledge of their past history, origin and culture is like a tree without roots." Marcus Garvey',
    '"An investment in knowledge pays the best interest." Benjamin Franklin',
    '"Any fool can know. The point is to understand." Albert Einstein',
    '"As knowledge increases, wonder deepens." Charles Morgan',
    '"Be a lifelong student. The more you learn, the more you earn and more self-confidence you will have." Brian Tracy',
    '"Beware of false knowledge; it is more dangerous than ignorance." George Bernard Shaw',
    '"Courage doesn’t always roar. Sometimes courage is the quiet voice at the end of the day saying, ‘I will try again tomorrow.’" — Mary Anne Radmacher',
    '"Do not follow where the path may lead. Go, instead, where there is no path and leave a trail." — Ralph Waldo Emerson',
    '"How much knowledge you gain depends on your willingness to learn." Anonymous',
    '"Human behavior flows from three main sources: desire, emotion, and knowledge." Plato',
    '"I want to be all used up when I die." — George Bernard Shaw',
    '"If you cannot do great things, do small things in a great way." — Napoleon Hill',
    '"If you have knowledge, let others light their candles in it." Margaret Fuller',
    '"It is never too late to be who you might have been." — George Eliot',
    '"It is not knowledge which should come to you, it is you who should come to the knowledge." Imam Malik',
    '"Knowledge has a beginning but no end." Geeta Iyengar',
    '"Knowledge is life with wings." William Blake',
    '"Knowledge is like a garden; if it is not cultivated, it cannot be harvested." Proverb',
    '"Knowledge is love and light and vision." Helen Keller',
    '"Knowledge is of no value unless you put it into practice." Anton Chekhov',
    '"Knowledge is power but enthusiasm pulls the switch." Anonymous',
    '"Knowledge is power. And you need power in this world. You need as many advantages as you can get." Ellen DeGeneres',
    '"Knowledge is the life of the mind." Abu Bakr As-Siddiq (RA)',
    '"Knowledge is the treasure of a wise man." Anonymous',
    '"Knowledge with action converts adversity into prosperity." A.P.J. Abdul Kalam',
    '"Let him who would move the world, first move himself." – Socrates',
    '"Life is not measured by the number of breaths we take, but by the moments that take our breath away." — Maya Angelou',
    '"Life is traveling to the edge of knowledge, then a leap is taken." D.H. Lawrence',
    '"Life isn’t about finding yourself. Life is about creating yourself." — George Bernard Shaw',
    '"No man’s knowledge here can go beyond his experience." John Locke',
    '"One whose knowledge is confined to books and whose wealth is in the possession of others can use neither knowledge nor wealth when the need for them arises." Chanakya',
    '"Real knowledge is to know the extent of one’s ignorance." Confucius',
    '"Self-confidence results, first, from exact knowledge; second, the ability to impart that knowledge." Napoleon Hill',
    '"Share your knowledge. It is a way to achieve immortality." Dalai Lama',
    '"Sharing knowledge occurs when people are genuinely interested in helping one another develop new capacities for action; it is about creating learning processes." Peter Senge',
    '"Smile, breathe and go slowly." — Thich Nhat Hanh',
    '"That knowledge which purifies the mind and heart alone is true knowledge, all else is only a negation of knowledge." Ramakrishna',
    '"The good life is one inspired by love and guided by knowledge." Bertrand Russell',
    '"The true method of knowledge is experimentation." William Blake',
    '"There is no wealth like knowledge, no poverty like ignorance." Buddha',
    '"We are drowning in information but starved for knowledge." John Naisbitt',
    '"We can have all the knowledge in the world, but it means nothing without the wisdom to know what to do with it." Marie Osmond',
    '"What lies behind us and what lies before us are tiny matters compared to what lies within us." — Ralph Waldo Emerson',
    '"Your earning ability today is largely dependent upon your knowledge, skill and your ability to combine that knowledge and skill in such a way that you contribute value for which customers are going to pay." Brian Tracy',
    '"Zeal without knowledge is fire without light." Thomas Fuller',
  ];
  let usedquote = quotes[Math.floor(Math.random() * (quotes.length - 1 + 1))];

  // console.log(shuffledPosts[0])
  return (
    <>
      <div className="main-wrap">
        <div className="container">
          {/* I need backend service connected to have users send an email to my account */}
          <i
            className="fa fa-paper-plane fa-2x top-bar-email"
            aria-hidden="true"
            onClick={handleShow}
          ></i>

          <Modal show={show} onHide={handleClose}>
            <Modal.Header closeButton>
              <Modal.Title></Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <>
                <Modal.Body>
                  <p>
                    Find the best education, career and learning resources for
                    the field of study you're interested in. Discuss your skills
                    and career prospects to gain a head start on your career.
                    <p />
                    <a href="https://www.buymeacoffee.com/yabc" target="_blank">
                      Support Us
                    </a>
                    <p>yabcinfo@gmail.com</p>
                  </p>
                </Modal.Body>
              </>
            </Modal.Body>
            <Modal.Footer>
              {/* <Button
                variant="primary"
                onClick={handleClose}
                type="submit"
                value="Submit"
              >
                Close
              </Button> */}
            </Modal.Footer>
          </Modal>

          <Link to="/search">
            <i className="fa fa-search fa-2x top-bar" aria-hidden="true"></i>
          </Link>
          <div className="main-slide">
            <div style={{ padding: "10%" }}>
              <h1>Young After Before College</h1>
            </div>
            <p>{usedquote}</p>
          </div>
        </div>

        <div class="container">
          <div>
          <Hangman />
          </div>
        </div>




        <h2 className="cards-title">Recently Viewed</h2>

        <div class="cards">
          {shuffledPosts[0].map((data, index) => {
            return (
              <div class="card">
                <img class="card" src={data.bookimg} alt="not read" />
                <div>
                  <strong>Title: </strong>
                  {data.bookname}
                </div>

                <Link
                  key={index}
                  // to={{
                  //     pathname: '/categories/' + val.category,
                  //     state: {catindex:bookData[index].category}
                  //   }}
                  to={`/categories/${data.category}`}
                  state={{ findcatindex: data.category }}
                >
                  <div>
                    <strong>Program: </strong>
                    {data.category}
                  </div>
                </Link>
              </div>
            );
          })}
        </div>
        <br />
        <div class="cards">
          {shuffledPosts[1].map((data, index) => {
            return (
              <div class="card">
                {/* <img class="card" src={data.vidlink} alt="not read"/> */}
                <iframe
                  src={data.vidlink}
                  frameborder="0"
                  allow="autoplay; encrypted-media"
                  allowfullscreen
                  title="video"
                  // style={{height: 400,width: 630}}
                />

                <div>
                  <strong>Title: </strong>
                  {data.vidtitle}
                </div>
                <Link
                  key={index}
                  // to={{
                  //     pathname: '/categories/' + val.category,
                  //     state: {catindex:bookData[index].category}
                  //   }}
                  to={`/categories/${data.category}`}
                  state={{ findcatindex: data.category }}
                >
                  <div>
                    <strong>Program: </strong>
                    {data.category}
                  </div>
                </Link>
              </div>
            );
          })}
        </div>

        <div class="container-fluid">
          <div class="container-xl">
            <div class="row">
              <div class="col">
                <script
                  async
                  src="https://pagead2.googlesyndication.com/pagead/js/adsbygoogle.js?client=ca-pub-8560056249871315"
                  crossorigin="anonymous"
                ></script>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
