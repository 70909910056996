import React, { useState } from "react";
import { Carousel } from "react-bootstrap";
import { Link } from "react-router-dom";
// import { Card } from "react-bootstrap";
// import axios from "axios";
import Button from 'react-bootstrap/Button';
import { bookData } from "./data";
import "bootstrap/dist/css/bootstrap.min.css";


const shuffleArray = array => {
  for (let i = array.length - 1; i > 0; i--) {
    const j = Math.floor(Math.random() * (i + 1));
    const temp = array[i];
    array[i] = array[j];
    array[j] = temp;
  }
}

export const CategoryPage = (props) => {
  let i;
  var x;
  var bookDatalength = bookData.length;
  for (i = 0; i < bookData.length; i++) {
    if (bookData[i].category === props.match.params.category) {
      x = i;
      // console.log("x in loop " + x);
      break;
    }
  }

  let objectused = bookData[x];
  // console.log("x " + x);

  const [visible, setVisible] = useState(
    objectused["careerlist"].length >= 4 ? 4 : objectused["careerlist"].length
  );
//  const [cardData, setCardData] = useState([]);

  // const allCardData = async () => {
  //   const response = await axios.get("https://randomuser.me/api/?results=35");
  //   setCardData(response.data.results);
  // };

  let objectusedzippachartlink = objectused["zippachartlink"];
  const loadMore = () => {
    setVisible(visible + 8);
  };

  let objectusedcarrerlist= shuffleArray(objectused["careerlist"]);

  // useEffect(() => {
  //   allCardData();
  // }, []);

  const renderCard = (data, index) => {
    return (
      <div class="card">
        <iframe
          src={data.link}
          frameborder="0"
          allow="autoplay; encrypted-media"
          allowfullscreen
          title="video"
          // style={{height: 400,width: 630}}
        />

        <div>
          <strong>Title: </strong>
          {data.title}
        </div>
      </div>
    );
  };

  function refreshPage() {
    window.location.reload(false);
  }

  // const { index, setIndex } = useState(0);

  return (
    <>
      <div>
        <div>
        <Link to='/search'><i className="fa fa-search fa-2x top-bar-search" aria-hidden="true" ></i></Link>

          <Link to={`/`}>
            <i
              className="fa fa-home fa-2x top-bar-back-home"
              aria-hidden="true"
            ></i>
          </Link>

          {x >= 1 && (
            <Link
              to={`/categories/${bookData[x - 1].category}`}
              state={{ findcatindex: bookData[x - 1].category }}
            >
              <i
                className="fa fa-arrow-left fa-2x top-bar-back"
                aria-hidden="true"
              ></i>
            </Link>
          )}

          {x < bookDatalength - 1 && (
            <Link
              to={`/categories/${bookData[x + 1].category}`}
              state={{ findcatindex: bookData[x + 1].category }}
            >
              <i
                className="fa fa-arrow-right fa-2x top-bar"
                aria-hidden="true"
              ></i>
            </Link>
          )}
        </div>
        <br />

        <div className="main-wrap">
          <div>
            <h2 className="cards-title">{bookData[x].category}</h2>
          </div>

          {/* <div class="cardszippa">
          <div style={{ maxWidth: "768px" }}>
            <div style={{ position: "relative", paddingTop: "580px" }}> */}

          <div>
            <div className="container-fluid">
              <div className="row">
                <div className="col-12">
                  <Carousel id="inverted">
                    {objectusedzippachartlink.map((obj, index) => {
                      return (
                        <Carousel.Item>
                          <div id="inverted">
                            <iframe
                              className="d-block w-100 "
                              style={{
                                // position: "relative",
                                // top: 0,
                                // left: 0,
                                
                                width: "100%",
                                height: "500px",
                                background: "#CFDBD5",
                                // display: "block",
                                // marginLeft: "auto",
                                // marginRight: "auto",
                              }}
                              title={obj}
                              src={obj}
                              // frameborder="no"
                              // scrolling="no"
                            ></iframe>
                          </div>
                        </Carousel.Item>
                      );
                    })}
                  </Carousel>
                </div>
              </div>
            </div>
          </div>

          <div class="container-fluid">
<div class="container-xl">
<div class="row">
<div class="col">
          <script async src="https://pagead2.googlesyndication.com/pagead/js/adsbygoogle.js?client=ca-pub-8560056249871315"
     crossorigin="anonymous"></script>
  </div></div></div></div>

          {/* </div>{" "}
          </div>
        </div> */}

          <div class="cards">
            {objectused["booklist"].map((data, index) => {
              return (
                <div class="card">
                  <a href={data.clickevent} target="_blank"><img class="card" src={data.image.slice(1)} alt="not read"  /></a>
                  <div>
                    <strong>Title: </strong>
                    {data.title}
                  </div>
                </div>
              );
            })}
            <div>
            <script async src="https://pagead2.googlesyndication.com/pagead/js/adsbygoogle.js?client=ca-pub-8560056249871315"
     crossorigin="anonymous"></script>
              
              </div>
          </div>

          <br />

          {/* {visible>20?<div className="btndiv"><button className="btn" onClick={refreshPage}><i className="fa fa-refresh" aria-hidden="true" ></i></button></div>:console.log("")} */}
          
          <div>
            <div className="container-fluid">
              <div className="row">
                <div className="col-12">
                  <Carousel id="jobtitle">
                    {objectused["jobtitles"].map((obj, index) => {
                      return (
                        <Carousel.Item>
                          <div id="jobtitle">
                            <button
                              className="d-block w-100 "
                              style={{
                                // position: "relative",
                                // top: 0,
                                // left: 0,
                                width: "100%",
                                height: "100px",
                                background: "#CFDBD5",
                                border:"none"
                                // display: "block",
                                // marginLeft: "auto",
                                // marginRight: "auto",
                              }}
                              
                              // frameborder="no"
                              // scrolling="no"
                            >{obj}</button>
                          </div>
                        </Carousel.Item>
                      );
                    })}
                  </Carousel>
                </div>
              </div>
            </div>
          </div>

          <div class="cards">
            {objectused["careerlist"].slice(0, visible).map(renderCard)}
          </div>

          <div className="btndiv">
            {visible < objectused["careerlist"].length && (
              <Button variant="outline-dark" onClick={loadMore}>
                More
              </Button>
            )}
            <br />
            {visible >= 20 ? (
              <Button variant="outline-dark" onClick={refreshPage}>
                {/* <i className="fa fa-refresh" aria-hidden="true"></i> */}
                Reset
              </Button>
            ) : (
              console.log("")
            )}
          </div>

          <div class="container-fluid">
<div class="container-xl">
<div class="row">
<div class="col">
          <script async src="https://pagead2.googlesyndication.com/pagead/js/adsbygoogle.js?client=ca-pub-8560056249871315"
     crossorigin="anonymous"></script>
  </div></div></div></div>

        </div>
      </div>
    </>
  );
};

export default CategoryPage;
