import React from 'react'

import { Link} from 'react-router-dom';
import { bookData } from "./data";



export const Program = () => {
    return (
      <>
        <div className="category-menu">
        <ul>
            {   
              bookData.map((item, index) => {
                // console.log('/categories/' + item.category)
                return (
                   <div className="">
                   <Link key={index} to={{
                pathname: '/categories/' + item.category,
                state: {categoryindex:bookData[index].category}
              }}> <li><button>{item.category}</button></li></Link>
                   </div>
                )
              })
            }
        </ul>
      </div>
      
      </>
    );
  };
  
