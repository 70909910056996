import { div } from "prelude-ls";
import React,{useState} from 'react'
import "../App.css";
import { bookData } from "./data";
import { Link } from 'react-router-dom';

export const SearchList = () => {
    const [searchTerm,setSearchTerm] = useState('')
  return (
    <>
    <div className="search-list">
      <input type="text" className="input" placeholder="seach field of study..." onChange={e=>setSearchTerm(e.target.value)} />
      {bookData.filter((val)=>{
                  if(searchTerm === ""){
                    
                    return null                    
                  }
        else if(val.category.toLowerCase().includes(searchTerm.toLowerCase())){
          // console.log(val.jobtitles.includes(searchTerm))
          return val.category;
        }
      }).map((val,index)=>{
          console.log('/categories/' + val.category)
        return  (
            <Link className="item" key={index} 
            
            // to={{
            //     pathname: '/categories/' + val.category,
            //     state: {catindex:bookData[index].category}
            //   }}
            to= {`/categories/${val.category}`}
            state={{ findcatindex:bookData[index].category }}
              >
            <div className="item-title">{val.category} </div>
            {/* <div className="item-author">{item.metadata.author.title}</div> */}
            </Link> 
         )
      })}
    </div>


    </>
  );
};