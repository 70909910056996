import React from 'react'
import { Link } from 'react-router-dom';
import { Program } from "./program";
import {SearchList} from './searchlist';


export const Icon = () =>  {
  return (
    <div className="search">
    <Link to='/'><i className="fa fa-times fa-2x top-bar" aria-hidden="true"></i></Link>
    <SearchList />
    <Program/>

    </div>
  )
}
